import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../components/Layout/Layout"
import componentStyles from "./plain-text-page.module.scss"
import SEO from "../../components/seo"

const PlainTextPage = ({ data, location }) => {
  const intl = useIntl()
  const page = data.markdownRemark
  const { title } = page.frontmatter

  const textPageClasses = classNames(componentStyles.textPage, "container")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={page.excerpt}
        title={title}
        pathname={location.pathname}
      />
      <div className={textPageClasses} dir="ltr">
        <h1 className="large-section-title">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
      </div>
    </Layout>
  )
}

export default PlainTextPage

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
      excerpt(pruneLength: 160)
    }
  }
`
